import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'

import React, { useState } from 'react';

function ThreeDBox() {

  return (
    <div className='responsiveDiv'>

    <h1 className='mt-[1rem] font-bold'>3DBox</h1>
    <img src={homeCarousel4} className='w-full m-auto'></img>
    <div className='text-left'>
    <p className='text-left mt-[1rem]'>The 3DBox is our first version of a spatial sound system for research and creative practice.
    Our main objective is to provide a space that can be used across all disciplines.</p>
   
   
    <h4 className='text-left'>Setup and Hardware</h4>
    <p>The 3DBox sound system consists of 28 Neumann KH120 MKII loudspeakers, mounted on aluminum trusses.</p>
    <img src={homeCarousel3} className='w-[80%] m-auto'></img>
    <p className='mt-[1rem] text-left font-bold'><li>Dimensions: 4.7 x 5.2 x 3.7 meters</li>
    <li>AES67 ready speakers, combined with a DANTE network.</li></p>

    <h4 className='text-left'>Rendering Software</h4>
    <p className='italic text-red-600'>Our system can be used with different rendering solutions. Some of them in a more conventional workflow, others more experimental.</p>
    
    <h5 className='text-left'>Ambisonics Solution</h5>
    <p className=''>The rendering software runs on a Linux computer with a Klark
Teknik DN9630 audio interface. A Jack server is started with a
sample rate of 48 000 Hz and a buffer size of 64 samples, resulting
in an audio output latency of 1.33 ms.
<br></br>
<br></br>
A custom Ambisonics encoding system5 is implemented, based
on SC-HOA, a Higher-Order Ambisonics extension for SuperCollider. This system utilizes fifth-order Ambisonics to
generate four virtual sound sources, which are controlled through
spherical coordinates received via OSC messages.</p>

    
    <h5 className='text-left'>Experimental Panning System</h5>
    <p className=''>Graduate student Xinni Li is currently working on a custom implementation of an experimental VBAP based algorithm.</p>
    
    <h4 className='text-left'>Construction</h4>
    <p className=''>The whole system has been built by students from our team and the School of Music.</p>
    

    <figure class="video">
        <iframe src="https://www.youtube.com/embed/Sc1QSskVY5E?si=10L_DO1TPmfZXZHY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen" ></iframe>
    </figure>
  </div>

  </div>
  );
}

export default ThreeDBox;