import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import Scz1 from '../media/schizo2-icn_ts-1.png'
import nas from '../media/networkaudio.jpg'
import nasStudents from '../media/nastudents.jpeg';
import crowd from '../media/crowd.jpeg';
import mesh from '../media/mesh2.jpeg';
import mesh1 from '../media/mesh1.jpeg';
import mesh2 from '../media/mesh3.jpeg';
import discount from '../media/discount.jpeg';


import React, { useState } from 'react';

function Mesh() {

  return (
    <div className='responsiveDiv'>

    <h1 className='mt-[1rem] mb-[1rem] font-bold'>MËSH</h1>
    


    <figure class="video">
    <iframe src="https://www.youtube.com/embed/z1KnUYmNNnI?si=kxO7tDxF_XtGkXKM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen" ></iframe>
</figure>
  

    

    
    
    <div className='text-left'>
    <p className='text-left mt-[1rem]'>

    Network systems can be used to transmit audio signals in local or remote scenarios. This allows rehearsals and performances across long distances with large numbers of participants. But beyond this, however, network technologies can be an integral part of the creative process.
<br></br>
<br></br>
Formerly called AnarX, the Atlanta Network Audio Reasearch Experience, explores the use of local and global networks for the composition and performance of music and sound art.
<br></br>
<br></br>
<img src={nas} className='w-full m-auto'></img>
<br></br>

 </p>

<h4 className='text-left'>Spring '24</h4>
<p>In Spring 2024 we conducted an initial test with a group of four undergraduate students for their capstone project. </p>
    
   
<img src={nasStudents} className='w-full m-auto'></img>
    <br></br>


    <h4 className='text-left'>Fall '24</h4>
<p>In Fall 2024, the experiment was opened as a course called Technology Ensemble. Throughout the semester students learned concepts such as jack, jacktrip, and supercollider. They tested different network map configurations to interactively make music with their peers. Specifically, they tested ring and mesh configurations.
  
  
  <br></br>  
  <br></br>
  The class concluded with a network audio concert at The Goat Farm in Atlanta.  </p>
  <img src={mesh} className='w-full m-auto'></img>
  <img src={crowd} className='w-full m-auto'></img>
  <img src={mesh1} className='w-full m-auto'></img>
  <img src={discount} className='w-full m-auto'></img>
  <img src={mesh2} className='w-full m-auto'></img>

    
   
  </div>

  </div>
  );
}

export default Mesh;