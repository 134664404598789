import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import Scz1 from '../media/schizo2-icn_ts-1.png'
import nas from '../media/networkaudio.jpg'
import nasStudents from '../media/nastudents.jpeg'
import sinusTon1 from '../media/sinusTon1.jpg'

import React, { useState } from 'react';

function Immersend() {

  return (
    <div className='responsiveDiv'>

    <h1 className='mt-[1rem] mb-[1rem] font-bold'>ImmersEND</h1>


    <img src={sinusTon1} className='w-full m-auto'></img>

    

   
    

    
    
    <div className='text-left'>
    <p className='text-left mt-[1rem]'>
    ImmersEND is as interactive soundscape powered by augmented reality. It was debuted at the SinusTon electroacoustic music festival in Magdeburg, Germany.
The experience mixes augmented reality and spatial audio. 
<br></br>
<br></br>
By accessing a mobile web application on their phone, audience members could join the shared soundscape.
As they walked around, their phone would recognize markers that were fixed onto the floor. Their phones would then project augmented objects onto the markers. Based on how they interacted with those objects, the sound sources would change. 
<br></br>
<br></br>
<figure class="video">
        <iframe src="https://www.youtube.com/embed/Vx8KEIgrGJM?si=vW_UBMftHYGvHCSj" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen" ></iframe>
    </figure>

<br></br>


The instillation used 8 speakers set up in a ring format. There were 4 different zones (2 speakers each) that the audience could travel between. 
Each zone had its own theme.<br></br>

<br></br>

In total, there were 29 different augmented objects to interact with. The instillation ran for three hours and had 30-35 people interact with it.
</p>

<br></br>

    <br></br>
  </div>

  </div>
  );
}

export default Immersend;